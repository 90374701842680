import imgBenzinga from '../../../assets/icons/press/benzinga@2x.svg'
import imgYahoo from '../../../assets/icons/press/yahoofinance@2x.svg'
import imgIbsintelligence from '../../../assets/icons/press/ibsintelligence@2x.svg'
import imgIndependent from '../../../assets/icons/press/independent@2x.svg'
import imgPymnts from '../../../assets/icons/press/pymnts@2x.svg'
import imgFintechalliance from '../../../assets/icons/press/fintechalliance@2x.svg'
import imgFinancemagnates from '../../../assets/icons/press/financemagnates@2x.svg'
import imgBusinessreporter from '../../../assets/icons/press/businessreporter@2x.svg'
import imgAws from '../../../assets/icons/press/aws@2x.svg'
import imgFintechnewsswitzerland from '../../../assets/icons/press/fintechnewsswitzerland@2x.svg'
import imgThepaypers from '../../../assets/icons/press/thepaypers@2x.svg'
import imgFfbankingtechnology from '../../../assets/icons/press/ffbankingtechnology@2x.svg'
import imgFintechfutures from '../../../assets/icons/press/fintechfutures@2x.svg'
import imgFinews from '../../../assets/icons/press/finews@2x.svg'
import imgFfnews from '../../../assets/icons/press/ffnews@2x.svg'
import imgFintechbuzz from '../../../assets/icons/press/fintechbuzz@2x.svg'
import imgSeekingalpha from '../../../assets/icons/press/seekingalpha@2x.svg'
import imgFinextra from '../../../assets/icons/press/finextra@2x.svg'
import imgInsider from '../../../assets/icons/press/insider@2x.svg'

export default [
  {
    name: 'seekingalpha',
    url:
      'https://seekingalpha.com/pr/19406405-klarpay-ag-launches-usd-denominated-visa-debit-cards',
    img: imgSeekingalpha,
  },
  {
    name: 'fintechnewsswitzerland',
    url:
      'https://fintechnews.ch/sponsored/klarpay-ceo-emphasizes-on-developing-a-digital-first-approach-to-banking/62832/',
    img: imgFintechnewsswitzerland,
  },
  {
    name: 'yahoofinance',
    url: 'https://finance.yahoo.com/news/klarpay-ag-appoints-marc-evans-142800063.html',
    img: imgYahoo,
  },
  {
    name: 'benzinga',
    url:
      'https://www.benzinga.com/pressreleases/23/06/n32663485/klarpay-ag-enhances-global-payment-solutions-with-local-ach-payments-in-40-currencies',
    img: imgBenzinga,
  },
  {
    name: 'ibsintelligence',
    url:
      'https://ibsintelligence.com/ibsi-news/klarpay-expands-ach-payment-offering-to-include-40-currencies/',
    img: imgIbsintelligence,
  },
  {
    name: 'finextra',
    url:
      'https://www.finextra.com/blogposting/26496/not-so-open-banking-fails-businesses-that-dare-to-dream-beyond-their-borders',
    img: imgFinextra,
  },
  {
    name: 'insider',
    url:
      'https://markets.businessinsider.com/news/stocks/klarpay-ag-expands-its-international-payments-by-enabling-usd-and-gbp-accounts-1031910180 ',
    img: imgInsider,
  },
  {
    name: 'independent',
    url:
      'https://www.independent.co.uk/news/business/business-reporter/fintech-banking-trends-transform-business-b2305087.html',
    img: imgIndependent,
  },
  {
    name: 'pymnts',
    url:
      'https://www.pymnts.com/real-time-payments/2023/fraud-and-liquidity-management-challenges-accompany-growth-in-instant-payments-use/',
    img: imgPymnts,
  },
  {
    name: 'fintechalliance',
    url:
      'https://fintech-alliance.com/news-insights/article/klarpay-ag-achieves-profit-in-its-first-year-of-operations ',
    img: imgFintechalliance,
  },
  {
    name: 'financemagnates',
    url:
      'https://www.financemagnates.com/fintech/swiss-fintech-klarpay-ends-2023-with-substanial-annual-profit/',
    img: imgFinancemagnates,
  },
  {
    name: 'businessreporter',
    url:
      'https://www.business-reporter.co.uk/finance/five-trends-set-to-transform-fintech-banking-in-2023',
    img: imgBusinessreporter,
  },
  {
    name: 'aws',
    url: 'https://aws.amazon.com/solutions/case-studies/Klarpay-case-study/',
    img: imgAws,
  },
  {
    name: 'fintechnewsswitzerland',
    url:
      'https://fintechnews.ch/sponsored/exclusive-interview-marc-evans-klarpay-coo-on-virtual-banking/56488/',
    img: imgFintechnewsswitzerland,
  },
  {
    name: 'thepaypers',
    url:
      'https://thepaypers.com/online-mobile-banking/klarpay-expands-range-of-international-payment-transactions--1259130',
    img: imgThepaypers,
  },
  {
    name: 'bankingtechnologymagazine',
    url:
      'https://www.fintechfutures.com/2022/09/banking-technology-magazine-september-2022-issue-out-now/',
    img: imgFfbankingtechnology,
  },
  {
    name: 'fintechfutures',
    url:
      'https://www.fintechfutures.com/2022/08/bridging-the-gap-between-online-companies-and-transactional-banking/?utm_source=social&utm_medium=linkedin&utm_campaign=ffm_interview_martynas_bieliauskas',
    img: imgFintechfutures,
  },
  {
    name: 'finews',
    url:
      'https://www.finews.com/news/english-news/61920-finews-first-mihkel-vitsur-fintech-license-klarpay?pk_vid=b236a8e895b0dea9171135374707fcc6',
    img: imgFinews,
  },
  {
    name: 'ffnews',
    url: 'https://ffnews.com/companies/klarpay-ag/',
    img: imgFfnews,
  },
  {
    name: 'fintechbuzz',
    url: 'https://fintecbuzz.com/?s=klarpay',
    img: imgFintechbuzz,
  },
]
